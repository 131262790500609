.card {
  margin-bottom: 5px !important;
}

.card-content {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.social-media {
  padding-bottom: 10px;
}

.link {
  color: #000;
}

@media (prefers-color-scheme: dark) {
  .link {
    color: var(--bulma-title-color);
  }

  html {
    background-color: #121212 !important;
  }

  .card {
    background-color: #121212 !important;
  }
}
